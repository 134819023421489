<template>
    <nav id="nav" class="site-header navbar navbar-toggleable-md fixed-top">
        <video-popup-window :enabled="video_popup" :videoOptions="videoOptions" @disableVideo="disableVideo"></video-popup-window>

        <div class="container">
            <div class="navbar-header">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="offcanvas" data-target=".navmenu" data-canvas="body">
					<span class="navbar-toggler-icon">
						<span class="lnr lnr-menu"></span>
					</span>
                </button>
                <a href="/" class="navbar-brand">
                    <Logo :logo_height="logo_height" />
                </a>
            </div>
            
            <side-menu @openPopupVideoWithSource="openPopupVideoWithSource" />

            <!-- Menu -->
            <div class="navbar-collapse collapse flex-row-reverse" id="navbar-bluein" aria-expanded="false">
                <ul class="pull-right menu nav main-nav navbar-nav">
                    <li v-if="!session.logged_in" class="menu-dropdown">
                        <a :href="links.login">
                            <div class="row">
                                <svg class="flag-icon"><use xlink:href="#user-1"></use></svg>
                            </div>
                            {{ commonTranslations['sign-in'] }}
                        </a>
                    </li>

                    <li v-show="parent.active" class="menu-dropdown" v-bind:key="category" v-for="(parent, category) in menu">
                        <a href="javascript:;" @click="iconClick(parent.link)" :class="parent.dropdown.length > 0 ? 'dropdown-toggle' : ''" data-toggle="dropdown">
                            <div class="row" style="margin: 0;">
                                <svg class="flag-icon"><use :href="`#${parent.icon}`"></use></svg>
                            </div>
                            {{ parent.name }}
                        </a>
                        <ul v-if="parent.dropdown.length > 0 && category !== 'videos'" class="dropdown-menu">
                            <li :class="child.class" v-bind:key="key" v-for="(child, key) in parent.dropdown">
                                <p v-if="child.active === false">{{ child.name }}</p>
                                <a v-if="child.active !== false" :target="child.target" class="nav-link" :href="child.link"><svg class="flag-icon"><use :href="`#${child.icon}`"></use></svg>{{ child.name }}</a>
                            </li>
                        </ul>
                        <ul v-else-if="parent.dropdown.length > 0" class="dropdown-menu">
                            <li :class="child.class" v-bind:key="key" v-for="(child, key) in parent.dropdown">
                                <a v-if="child.active !== false" :target="child.target" class="nav-link" @click="openPopupVideoWithSource(child.link, child.name)"><svg class="flag-icon"><use :href="`#${child.icon}`"></use></svg>{{ child.name }}</a>
                            </li>
                        </ul>
                    </li>

                    <li class="menu-dropdown" v-if="availableLanguages.length > 1">
                        <a class="dropdown-toggle">
                            <div class="row">
                                <svg class="flag-icon"><use :href="`#${activeLanguage.icon}`"></use></svg>
                            </div>
                            {{ activeLanguage.name }}
                        </a>
                        <ul class="dropdown-menu">
                            <li v-bind:key="key" v-for="(language, key) in dropdownLanguages">
                                <a class="nav-link" @click="languageChanged(language)"><svg class="flag-icon"><use :href="`#${language.icon}`"></use></svg>{{ language.name }}</a>
                            </li>
                        </ul>
                    </li>

                    <li v-if="!session.logged_in && !isSSO" class="menu-dropdown">
                        <a href="/auth/register" class="btn btn-theme btn-menu btn-round">{{ commonTranslations['registration-submit-btn'] }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import Logo from '@/components/Logo'
    import Menu from '@/components/Menu'
    import SideMenu from '@/components/home/SideMenu'
    import $ from 'jquery'

    import VideoPopupWindow from '@/components/VideoPopupWindow'

    export default {
        mixins: [Menu],
        data() {
            return {
                logo_height: 85
            }
        },
        components: {
            Logo,
            SideMenu,
            VideoPopupWindow
        },
        methods: {
            windowResize() {
                if (window.innerWidth < 500) {
                    this.logo_height = 55
                    this.video_width = window.innerWidth - 50
                } else {
                    this.logo_height = 85
                    this.video_width = window.innerWidth / 2
                }
            }
        },
        created() {
            $('body .popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
                zoom: {
                    enabled: true,
                    duration: 300,
                    opener: function(element) {
                        return element.find('img')
                    }
                }
            })

            window.addEventListener("resize", this.windowResize)
            this.windowResize()
        },
        unmounted() {
            window.removeEventListener("resize", this.windowResize);
        },
    }
</script>

<style scoped lang="scss">
    .navbar-toggler {
        position: absolute;
        right: 10px;
        top: -10px;
        align-self: flex-start;
        padding: 0.20rem 0.25rem 0 0.25rem !important;
        border: 0 !important;
        border-radius: 0 !important;
        font-size: 2.5rem !important;
    }

    ul li div.row {
        margin: 0;
    }

    .main-nav li.menu-dropdown {
        margin-right: 30px !important;
        text-align: center;
    }

    .dropdown-toggle::after {
        margin-left: 0.5em !important;
    }

    ul.dropdown-menu li svg {
        height: 28px !important;
    }

    ul.dropdown-menu {
        min-width: 15rem !important;
    }

    ul li.menu-dropdown a.btn-theme {
        color: #FFFFFF !important;
        margin-top: 50%;
        padding-left: 10px !important;
        padding-right: 10px !important;
        min-width: 150px;
        transform: translate(0, -50%);
    }
</style>
